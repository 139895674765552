import * as React from "react";
import { Row, Col, Container } from 'react-bootstrap'
// import { IZimiState } from '../../../types/auth'

import { Switch, Route, withRouter, RouteComponentProps } from "react-router-dom";
import { QRType } from "../../libs/QRDecodeMappings";

const PageMeshNGoUrl = 'https://support.zimi.life/hc/en-au/articles/900005340243-What-is-the-Mesh-Go-card-used-for-'
const PageAddUserUrl = 'https://support.zimi.life/hc/en-au/articles/900004357726-How-to-add-users-to-a-network';

interface IQrTokenMatch {
  qrToken: string;
}

type PageProps = RouteComponentProps<IQrTokenMatch>;

interface DispathProps {
  orgsLoadConnect: (orgId: string) => void;
}
interface ReduxStateProps {
}

interface IProps extends PageProps, ReduxStateProps, DispathProps {
}

interface IState {
}

class QRUrlB extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      orgs: [],
    }
  }

  render() {

    const bUrlQueryParams = this.parseQuery(this.props.location.search);

    if (bUrlQueryParams.qr === QRType.meshandgo) {
      window.location.href = PageMeshNGoUrl
    }
    if (bUrlQueryParams.qr === QRType.adduser) {
      window.location.href = PageAddUserUrl
    }
    if (bUrlQueryParams.qr === undefined) {
      window.location.href = '/error'
    }

    return (

      <Container>

        <Switch>
          <Route path='/b'>
            <Row> <Col> <h2>QR B page</h2></Col></Row>
            <Row> <Col> <h3> {JSON.stringify(this.parseQuery(this.props.location.search))} </h3></Col></Row>
          </Route>

        </Switch>

      </Container>


    );
  };

  UNSAFE_componentWillMount() {

  }

  parseQuery(queryString: string) {
    var query: any = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
  }
}

export default ((withRouter(QRUrlB)));
